.App {
    text-align: center;
    font-family: "roboto_bold", Arial, Verdana, sans-serif;
    padding-bottom: 50px;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.basic-multi-select {
    display: inline-block;
    margin-top: 0px;
    padding: 0px;
}

.input-rayon {
    align-items: center;
    background-color: hsl(0, 0%, 100%);
    border-color: hsl(0, 0%, 80%);
    border-radius: 4px;
    border-style: solid;
    border-width: 1px;
    cursor: default;
    text-align: center;
    min-height: 38px;
    transition: all 100ms;
    box-sizing: border-box;
    width: 400px;
}

.block-inputs-ordec {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;
}

.block-inputs-ordec .select__control {
    z-index: 2;
}

.block-inputs-ordec .select__menu {
    z-index: 3;
}

h1 {
    font: 22px Museo, serif;
    color: #75b54e;
    margin: 5px 0;
    font-weight: bold;
}

.error-block {
    height: 20px;
    display: block;
}
.alert-hidden {
    opacity: 0;
    transition: all 250ms linear 2s;
}

.alert-shown {
    opacity: 1;
    transition: all 250ms linear;
}

.hidden {
    display: none;
}

.shown {
    display: block;
}

.error-message {
    color: darkred;
    font-weight: bold;
}

.success-message {
    color: darkgreen;
    font-weight: bold;
}

/* Résultats de la recherche */

table {
    border-collapse: collapse;
    border-spacing: 0;
}
table.results_ordec {
    width: 930px;
    table-layout: fixed;
}
.results_ordec tr {
    display: table-row;
}
.results_ordec th {
    text-align: center;
    font-weight: 700;
    color: #fff;
    background: #2d7ec1;
    border: 1px solid #fff;
    padding: 5px;
    width: 20px;
    overflow: hidden;
}

.results_ordec td {
    border: 1px solid #fff;
    padding: 5px;
    background: #f9f9f9;
    transition: background 0.5s ease;
    width: 20px;
    overflow: hidden;
}
.results_ordec tr:nth-child(2n) td {
    background: #f2f2f2;
}
.results_ordec tr.filtering-row,
.results_ordec tr.filtering-row th {
    background-color: lightgray;
}

.results_ordec button.details {
    background: none;
    border: none;
    padding: 0;

    text-decoration: underline;
    cursor: pointer;
}

.displayed_tab {
    display: table;
    width: 100%;
}

.hidden_tab {
    display: none;
}

.table-tabs-group .table-tabs-item.tab-active,
.table-tabs-group .table-tabs-item:hover {
    background: #2d7ec1;
}
.table-tabs-group .table-tabs-item {
    padding: 10px 9px 10px 39px;
    background: #75b54e;
    color: #fff;
    float: left;
    margin: 0 1px 0 0;
    cursor: pointer;
    font-size: 14px;
    position: relative;
    border-top-right-radius: 15px;
}

.table-tabs-item img {
    position: absolute;
    left: 5px;
    top: 5px;
}

/* Page de détails */

.container-details {
    display: grid;
    grid-template-columns: 2fr 3fr;
    column-gap: 20px;
    margin-right: 30px;
    margin-left: 30px;
}

.calendar-table {
    color: #000;
    margin-left: auto;
    margin-right: auto;
    width: 70%;
}

.calendar-table th {
    text-align: center;
    font-weight: 700;
    color: #fff;
    background: #2d7ec1;
    border: 1px solid #fff;
    padding: 5px;
}

.calendar-table td.blue-td {
    background: #2d7ec1;
    color: white;
}

.calendar-table td {
    border: 1px solid #fff;
    padding: 5px;
    background: #f9f9f9;
    transition: background 0.5s ease;
    text-align: center;
}
.calendar-table tr:nth-child(2n) td {
    background: #f2f2f2;
}

th.absent-td {
    background-color: rgba(0, 0, 0, 0);
    border: 0px;
}

.dechets_acceptes {
    display: grid;
    text-align: left;
    grid-template-columns: 1fr 1fr 1fr;
}

.dechets_acceptes > div {
}

.listCenter li span:first-child {
    text-align: right;
    font-weight: 700;
}

.listCenter li span:nth-child(2) {
    text-align: left;
}

.listCenter li span {
    display: block;
    float: left;
    padding-left: 20px;
    width: 50%;
    word-wrap: break-word;
}

ul.listCenter li {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
}

.bigLabel {
    border: 3px solid #75b54e;
    color: #75b54e;
    display: inline-block;
    font-size: 13px;
    margin: 40px auto;
    max-width: 150px;
    padding: 6px 20px;
    border-radius: 20px;
}

.list-inline > li {
    display: inline-block;
    padding-left: 0px;
    padding-right: 5px;
}

ul.list-inline li + li::before {
    content: "-";
    display: inline;
    position: relative;
    margin-right: 10px;
}

ul.list-inline {
    padding-left: 0px;
}

.blockClassic {
    background-color: #ecf4fb;
    border-radius: 20px;
    border: 1px solid #0a98db;
    margin-bottom: 15px;
    padding: 10px;
    padding-left: 20px;
    padding-right: 20px;
}

.blockClassic h3 {
    text-align: left;
    color: #0a98db;
    font-weight: 700;
    padding-bottom: 3px;
    text-transform: uppercase;
}

.blockClassic h4 {
    text-align: left;
    color: #0a98db;
    line-height: 20px;
}

.blockClassic ul {
    list-style: none;
    text-align: left;
}

.h4-one-liner {
    display: inline-block;
    text-align: left;
    margin-right: 40px;
    margin-bottom: 16px;
    margin-top: 0px;
}

.right-details {
    text-align: left;
}

/* POPUP */

.overlay {
    z-index: 4;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.7);
    transition: opacity 500ms;
    visibility: visible;
    opacity: 1;
    overflow-y: auto;
}

.popup {
    z-index: 4;
    margin: 20px;
    padding: 20px;
    background: #fff;
    border-radius: 5px;
    position: relative;
    transition: all 5s ease-in-out;
}

.popup h2 {
    margin-top: 0;
    color: #333;
    font-family: Tahoma, Arial, sans-serif;
}
.popup .close {
    position: absolute;
    top: 20px;
    right: 30px;
    transition: all 200ms;
    font-size: 30px;
    font-weight: bold;
    text-decoration: none;
    color: #75b54e;
}

.popup .directLink {
    position: absolute;
    top: 20px;
    right: 60px;
    transition: all 200ms;
    font-size: 30px;
    text-decoration: none;
    color: #75b54e;
}
.popup .close:hover,
.popup .directLink:hover {
    color: #2d7ec1;
}
.popup .content {
    max-height: 30%;
    overflow: auto;
}

@media screen and (max-width: 700px) {
    .box {
        width: 70%;
    }
    .popup {
        width: 70%;
    }
}

.disclaimer-message {
    margin: auto;
    margin-bottom: 10px;
    min-height: 40px;
    height: 40px; /* hauteur du parent */
    width: 60%;
    min-width: 500px;

    border-radius: 5px;

    line-height: 40px; /* hauteur de ligne (identique) */
    white-space: nowrap; /* interdiction de passer à la ligne */
}

.error {
    background-color: lightcoral;
    color: darkred;
    border: darkred solid 1px;
}

.warning {
    background-color: lightcoral;
    color: darkred;
    border: darkred solid 1px;
}

.sites-web-manquants {
    margin: auto;
    width: 50%;
    min-width: 500px;
    text-align: left;
}

.check-ok {
    background-color: #3d6633;
}

.check-notok {
    background-color: darkred;
}

.sites-web-manquants tr {
    border-bottom: 1px solid black;
    border-top: 1px solid black;
    border-collapse: collapse;
}

img.resultat-existence-site {
    height: 20px;
}

.leaflet-container {
    height: 100%;
    width: 100%;
    z-index: 1;
}

.leaflet-pane.leaflet-shadow-pane {
    display: none;
}

.leaflet-pane.leaflet-shadow-pane {
    display: none;
}

img.leaflet-marker-icon {
    background-color: rgba(0, 0, 0, 0);
    border: none;
}

.leaflet-marker-icon.leaflet-div-icon.marker-active {
    outline: red 4px solid;
    border-radius: 50%;
    outline-offset: 1px;
    outline-width: 110%;
}

.results_ordec tr.actor-active td {
    background-color: lightblue;
}

.map-container {
    height: 700px;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
}

.map-elements-container {
    display: grid;
    grid-template-columns: 80% 20%;
    column-gap: 20px;
    margin-left: 5%;
    margin-right: 5%;
}

.map-legend {
    /* background-color: lightblue;
    border-radius: 15px;
    border: 1px solid black; */
}

.markers-legend {
    display: grid;
    grid-template-columns: 1fr 3fr;
    row-gap: 20px;
}

.markers-legend .icon {
    width: 30px;
}

.striked-logo {
    border-bottom: 3px solid darkred;
    transform: rotate(45deg);
    width: 50px;
    margin-top: -20px;
    margin-left: -10px;
}

.striked-text {
    text-decoration: line-through;
}

img.marker-legend {
    width: 30px;
}

.results-container {
    /* width: 75%; */
    margin-top: 30px;
    margin-left: 25px;
    /* margin-right: auto; */
}

.resource-element {
    border-block: solid black 1px;
    border-top: none;
    padding: 20px;
}
.resource-toolbar {
    margin-top: 10px;
}

textarea.content {
    width: 450px;
    height: 250px;
}

.wastes-list {
    margin-left: 10px;
    margin-right: 10px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
}

.waste-element {
    border-radius: 15px;
}

.wastes-category {
    width: 70%;
    margin: auto;
    margin-bottom: 10px;
    padding: 10px;
    border-radius: 10px;
}

.wastes-category-list {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
}
.waste-in-category {
    position: relative;
    align-content: center;
    min-width: 100px;
    padding: 5px 25px 5px 25px;
    min-height: 30px;
    margin: 5px;
    border: 1px solid rgba(120, 120, 120, 0.25);
    border-radius: 5px;

    display: flex;
    justify-content: center;
    align-content: center;
    flex-direction: column;
}

.wastes-category.hazardous,
.waste-element.hazardous {
    border: 1px solid #df191c;
    background-color: #ffe1e2;
}

.wastes-category.non-hazardous,
.waste-element.non-hazardous {
    border: 1px solid #49aa46;
    background-color: #f1fff0;
}

.wastes-category.inert,
.waste-element.inert {
    border: 1px solid #5ecfff;
    background-color: #eeffff;
}

.wastes-links {
    padding: 10px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
}

.waste-link-element {
    border-radius: 9px;
    background-color: #fefefe;
    z-index: 10;
    padding: 4px 10px 4px 10px;
    position: relative;
}

.waste-link-input {
    margin: 0px;
}

.waste-link-delete {
    width: 12px;
    position: absolute;
    right: 8px;
    top: 8px;
}

.hazardous .waste-link-element {
    border: 1px solid #830e10;
}

.non-hazardous .waste-link-element {
    border: 1px solid #005500;
}

.inert .waste-link-element {
    border: 1px solid #1079b1;
}

.add-link {
    width: 15px;
    margin-right: 4px;
    filter: invert(19%) sepia(82%) saturate(1295%) hue-rotate(89deg)
        brightness(100%) contrast(105%);
}

.useful-resources {
    text-align: left;
    margin: auto;
    width: 75%;
}

.button-small {
    font-size: 85%;
}

.button-tiny {
    font-size: 65%;
}

.css-buttons-editable {
    display: inline;
    margin-left: 10px;
}

.update-explanations {
    width: 600px;
    margin: auto;
    text-align: justify;
}

.explanations {
    max-width: 750px;
    margin: auto;
    text-align: left;
}
.bouton_article {
    background-color: #75b54e;
    color: #fff !important;
    display: block;
    float: right;
    font-size: 12px;
    line-height: 25px;
    min-width: 94px;
    padding-left: 5px;
    padding-right: 35px;
    position: relative;
    z-index: 2;
    text-align: left;
}
.bouton_article.url {
    background-image: url("../public/img/pict-hyperlien.png");
    background-position: 100%;
    background-repeat: no-repeat;
}
.bouton_article .before,
.bouton_article::before {
    content: "";
    background: url(../public/img/sprite.png) no-repeat scroll -164px -14px
        transparent;
    width: 6px;
    height: 25px;
    position: absolute;
    left: -6px;
    display: block;
}

.export-button {
    margin: 10px;
}

ul.meta_data_actor,
ul.meta_data_actor > li {
    text-align: left;
    margin-left: -15px;
    font-size: 10px;
}
div.actor_edition {
    width: 50%;
    min-width: 800px;
    margin: auto;
}
.actor_edition ul.meta_data_actor,
.actor_edition ul.meta_data_actor > li {
    text-align: left;
    margin-left: 0px;
    font-size: 14px;
}

.button-success,
.button-error,
.button-warning,
.button-secondary {
    color: white;
    border-radius: 4px;
    text-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
}

.button-success {
    background: rgb(28, 184, 65);
    /* this is a green */
}

.button-error {
    background: rgb(202, 60, 60);
    /* this is a maroon */
}

.button-warning {
    background: rgb(223, 117, 20);
    /* this is an orange */
}

.button-secondary {
    background: rgb(66, 184, 221);
    /* this is a light blue */
}

.edit-icon {
    width: 12px;
    margin-right: 4px;
    margin-left: 4px;
}

.messages-msg {
    position: fixed;
    bottom: 40%;
    z-index: 999999;
    left: 50%;
    width: 400px;
    margin-left: -200px;
    text-align: center;
    font-weight: bold;
    padding: 35px;
    background-color: lightgray;
    border: 2px solid gray;
}
.alert-success {
    background-color: rgb(229, 254, 194);
    border-color: rgb(19, 184, 19);
}
.alert-error,
.alert-danger {
    background-color: rgb(255, 179, 179);
    border-color: rgb(146, 11, 11);
}
.alert-warning {
    background-color: rgb(255, 249, 196);
    border-color: rgb(255, 188, 5);
}

.main-app-choices {
    display: grid;
    grid-template-columns: 1fr 1fr;
    width: 100%;
}
.main-app-choice {
    border-bottom: 6px solid #d5e7f7 !important;
    padding-bottom: 10px;
    padding-top: 10px;
    width: 100%;
    font-weight: bold;
    color: #495057;
    text-decoration: none;
}
.main-app-choice:hover {
    background-color: #e8f0f7;
}
.main-app-choice.active {
    background-color: #d5e7f7;
    border-color: #0a98db !important;
}

.results-city-level {
    display: grid;
    grid-template-columns: 1fr 1fr;
}
.results-city-level h3 {
    color: #0c6e9c;
}
.results-city-level h4 {
    margin-top: -15px;
}
.results-city-level > div {
    margin: 10px;
    border: 1px solid #0a98db;
    border-radius: 25px;
    background-color: #d5e7f7;
}

.results-city-level ul {
    list-style: none;
    text-align: left;
}

.results-city-level a {
    color: #0c6e9c;
}
